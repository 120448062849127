import {useSiteData} from '../Page/SiteData';

export default function HrefLangs() {
  const {regions} = useSiteData();
  if (!regions || regions.length === 0) {
    return null;
  }
  const publishedRegions = regions.filter(
    ({published, hreflang}) =>
      published && hreflang !== 'en-DK' && hreflang !== 'en-BE',
  );
  return (
    <>
      {publishedRegions.map(({hreflang, href}) => (
        <link href={href} hrefLang={hreflang} key={hreflang} rel="alternate" />
      ))}
    </>
  );
}
